<h2 class="hcl-title" mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <form class="tag-dialog__form" [formGroup]="tagForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'NAME' | translate }}</mat-label>
      <input type="text" matInput formControlName="name" [placeholder]="'NAME' | translate" required>
    </mat-form-field>
    <span>{{ 'TAG.TARGET_TYPES' | translate }} :</span>
    <ng-multiselect-dropdown class="tag-dialog__form__targetTypes" name="targetTypes"
      [placeholder]="'TAG.SELECT_TARGETS' | translate" [settings]="dropdownTargetTypesSettings" [data]="TargetTypes"
      formControlName="targetTypes">
    </ng-multiselect-dropdown>
    <mat-checkbox formControlName="system" *cmnIfPerm="['scr:tag:update:system']">
      {{ 'TAG.SYSTEM' | translate }}
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="tag-dialog__actions">
  <button class="hcl-button" mat-button mat-dialog-close>{{ 'CANCEL' | translate }}</button>
  <button class="hcl-button-confirm" mat-raised-button [disabled]="!tagForm.valid" (click)="doSubmit()">
    {{ data.action }}
  </button>
</mat-dialog-actions>